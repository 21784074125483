import React, {useEffect, useState} from 'react';
import Spacer from '../Spacer/Spacer.jsx';
import styles from './BannerText.module.scss';
import {getBaseImagePath} from '../../../../ui/catalog/Utils';

const BannerText = (props) => {
	const [imageHeight, setImageHeight] = useState(0);
	const bannerImg = props.params.img;

	const updateImageHeight = () => {
		const img = document.querySelector('.banner-on-main img');
		if (img) {
			setImageHeight(img.offsetHeight);
		}
	};

	useEffect(() => {
		const img = document.querySelector('.banner-on-main img');
		if (img && img.complete) {
			updateImageHeight();
		} else if (img) {
			img.onload = updateImageHeight;
		}

		window.addEventListener('resize', updateImageHeight);

		return () => {
			window.removeEventListener('resize', updateImageHeight);
		};
	}, [bannerImg]);

	const hasTitle = props.params.title && props.params.title.trim() !== '';
	const hasText = props.params.text && props.params.text.trim() !== '';

	return (
		<>
			<div className="mblock">
				<div className={'text-page__main-banner'}>
					<div className={'banner-on-main ' + styles.centeredSlider}>
						<picture
							fullpage-banner-size=""
							style={{height: `${imageHeight}px`}}
						>
							<source
								media="(max-width: 992px)"
								srcSet={getBaseImagePath() + (props.params.imgmob ? props.params.imgmob : bannerImg)}
							/>
							<img
								src={getBaseImagePath() + bannerImg}
								alt={bannerImg}
								style={{display: 'block', width: '100%', height: 'auto'}}
							/>
						</picture>
						<div className="text">
							{hasTitle && (
								<h3 className="f-exp" dangerouslySetInnerHTML={{__html: props.params.title}}></h3>
							)}
							{hasText && <h5>{props.params.text}</h5>}
						</div>
					</div>
				</div>
				<div
					className="landing_imageHeight"
					style={{height: `${imageHeight}px`}} >
					&nbsp;
				</div>
			</div>
			<Spacer />
		</>
	);
};

export default BannerText;