import TileBannerInterface from "../../interfaces/main/TileBannerInterface";
import TileBannerItemInterface from "../../interfaces/main/TileBannerItemInterface";
import {useInView} from "react-intersection-observer";

import {Swiper, SwiperSlide} from "swiper/react";

interface TileBannerProps {
    tileBanner: TileBannerInterface;
}

const BigBanner = ({tileBannerItem}: {tileBannerItem: TileBannerItemInterface | undefined}) => {
    return (
        <div className="col">
            {tileBannerItem && (
                <a className="banner banner--big " href={tileBannerItem.url}>
                    <picture className="banner__img">
                        <source srcSet={tileBannerItem.image} media="(max-width: 992px)" />
                        <img src={tileBannerItem.image} alt="" />
                    </picture>
                    {tileBannerItem.title && <h2 className="banner__medium-title f-exp">{tileBannerItem.title}</h2>}
                    {tileBannerItem.header && <p className="banner__text">{tileBannerItem.header}</p>}
                    {tileBannerItem.buttonText && (
                        <a className="btn btn-primary" href={tileBannerItem.url}>
                            {tileBannerItem.buttonText}
                        </a>
                    )}
                </a>
            )}
        </div>
    );
};

const Banner = ({tileBannerItem}: {tileBannerItem: TileBannerItemInterface | undefined}) => {
    return (
        <div className="col col-banner">
            {tileBannerItem && (
                <div className="banner">
                    <a href={tileBannerItem.url}>
                        <picture className="banner__img">
                            <source srcSet={tileBannerItem.image} media="(max-width: 992px)" />
                            <img src={tileBannerItem.image} alt="" />
                        </picture>
                        <h3 className="banner__small-title f-exp">{tileBannerItem.header}</h3>
                    </a>
                </div>
            )}
        </div>
    );
};

const TileBanner = ({tileBanner}: TileBannerProps) => {
    const {ref, inView} = useInView({
        threshold: 0.5,
        triggerOnce: true,
    });

    const getBigBanner = (): TileBannerItemInterface | undefined => {
        if (tileBanner) {
            return tileBanner.items.find((item) => item.isBig);
        }
    };

    const getBanners = (from: number, to: number): TileBannerItemInterface[] => {
        return tileBanner.items.filter((item) => !item.isBig).slice(from, to);
    };

    const getDoubleBanner = (): TileBannerItemInterface[] => {
            if (tileBanner) {
                const items = tileBanner.items.slice(0, 2);
                return items.length === 2 ? items : [];
            }
            return [];
    };

    return (
        <>
            <div className={"page-title" + (tileBanner.header ? " page-title-header" : "")}>
                <div className="page-title__back">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col">
                                <p className="f-exp">{tileBanner.backgroundText}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-title__front">
                    <div className="container-fluid">
                        <div className="row">
                            {tileBanner.header && (
                                <div className="col text-center">
                                    <h2 className="text-center f-exp">{tileBanner.header}</h2>
                                </div>
                            )}
                            {tileBanner.description && (
                                <>
                                    <div className="col-lg-4 desk"></div>
                                    <div className="col-lg-4">
                                        <p className={"text-center"}>{tileBanner.description}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="collections__wrap">
                <div className="container-fluid">
                    <div className="row desk">
                        {tileBanner.type === "left" && getBigBanner() && (
                            <div className="col-lg-6">
                                <div className="row">
                                    <BigBanner tileBannerItem={getBigBanner()} />
                                </div>
                            </div>
                        )}
                        {tileBanner.type !== "double" && (
                        <div className="col-lg-3">
                            <div className="row gy-4">
                                {getBanners(0, 2).map((banner, index) => (
                                    <Banner key={index} tileBannerItem={banner} />
                                ))}
                            </div>
                        </div>
                        )}
                        {tileBanner.type === "center" && getBigBanner() && (
                            <div className="col-lg-6">
                                <div className="row">
                                    <BigBanner tileBannerItem={getBigBanner()} />
                                </div>
                            </div>
                        )}
                        {tileBanner.type !== "double" && (
                        <div className="col-lg-3">
                            <div className="row gy-4">
                                {getBanners(2, 4).map((banner, index) => (
                                    <Banner key={index} tileBannerItem={banner} />
                                ))}
                            </div>
                        </div>
                        )}
                        {tileBanner.type === "right" && getBigBanner() && (
                            <div className="col-lg-6">
                                <div className="row">
                                    <BigBanner tileBannerItem={getBigBanner()} />
                                </div>
                            </div>
                        )}
                        {tileBanner.type === "double" && getDoubleBanner().length === 2 && (
                            <div className="col-lg-12">
                                <div className="row">
                                    {getDoubleBanner().map((item, index) => (
                                        <div key={index} className="col-lg-6">
                                            <BigBanner tileBannerItem={item} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row mob">
                        <BigBanner tileBannerItem={getBigBanner()} />
                        <div className="col" ref={ref}>
                            <Swiper
                                className={`banner__slider ${inView ? "start__bounce" : ""}`}
                                slidesPerView={2}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 2,
                                    },
                                }}
                            >
                                {getBanners(0, 4).map((banner, index) => (
                                    <SwiperSlide key={index} className="swiper-slide banner">
                                        <a href={banner.url}>
                                            <picture className="banner__img">
                                                <source srcSet={banner.image} media="(max-width: 992px)" />
                                                <img src={banner.image} alt="" />
                                            </picture>
                                            <h3 className="banner__small-title f-exp">{banner.header}</h3>
                                        </a>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TileBanner;
